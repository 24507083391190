<template>
  <div class="transfer__body" v-loading="loading" :style="{height}">
    <div class="transfer-pane">
      <div class="transfer-pane__tools">
        <el-input placeholder="输入关键词进行搜索" v-model="filterText" @input="search"
                  clearable>
        </el-input>
      </div>
      <div class="transfer-pane__body">
        <el-tree :data="treeData" :props="props" default-expand-all ref="treeBox"
                 :expand-on-click-node="false" class="JNPF-common-el-tree" node-key="id"
                 v-loading="loading" :filter-node-method="filterNode" @node-click="handleNodeClick">
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <i :class="data.icon"></i>
            <span class="text">{{ node.label }}</span>
          </span>
        </el-tree>
      </div>
    </div>
    <div class="transfer-pane">
      <div class="transfer-pane__tools">
        <span>已选</span>
        <el-button @click="removeAll" type="text" class="removeAllBtn">清空列表</el-button>
      </div>
      <div class="transfer-pane__body shadow right-pane">
        <template>
          <div v-for="(item, index) in selectedTextData" :key=" index" class="selected-item">
            <span>{{ item.title }}</span>
            <i class="el-icon-delete" @click="removeData(item,index)"></i>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'JNPF-transfer',
  data() {
    return {
      selectedData: [],
      treeDataBak: [],
      selectedTextData: [],
      filterText: ''
    }
  },
  props: {
    height: {
      type: String,
      default: "400px"
    },
    loading: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    treeData: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    },
    props: {
      type: Object,
      default: () => {
        return {value: "id", label: "title", children: "children"};
      }
    },
    type: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters(["deptTree"]),
    allList() {
      if (!this.type) return []
      if (this.type == 'department') {
        return this.fnTree(this.deptTree)
      } else {
        return this.treeData
      }
    }
  },
  watch: {
    value(val) {
      if (this.treeDataBak.length == 0) {
        this.treeDataBak = this.treeData
      }
      this.initHandle()
    },
  },
  methods: {
    initHandle() {
      if (!this.value || !this.value.length) {
        this.selectedTextData = []
        this.selectedData = []
        return
      }
      const arr = this.multiple ? this.value : [this.value]
      let selectedTextData = []
      for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        inner: for (let j = 0; j < this.allList.length; j++) {
          if (item === this.allList[j].id) {
            selectedTextData.push(this.allList[j])
            break inner
          }
        }
      }
      this.selectedTextData = selectedTextData
      this.selectedData = this.selectedTextData.map(o => o.id)
    },
    fnTree(arr) {
      let data = JSON.parse(JSON.stringify(arr));
      let newData = [];
      const callback = (item) => {
        (item.children || (item.children = [])).map((v) => {
          callback(v);
        });
        delete item.children;
        newData.push(item);
      };
      data.map((v) => callback(v));
      return newData;
    },
    search(e) {
      if (e == '') {
        this.treeData = this.treeDataBak;
        return;
      }
      if (this.type == 'position') {
        this.treeData = this.treeDataBak.filter((item) => {
          if (item.title.indexOf(e) > -1) {
            return true
          }
        })
      } else {
        this.filterTree(e, this.treeData);
      }
    },
    filterTree(val, tree, newArr = []) {
      if (!(tree.length && val)) {
        return tree
      }
      for (let item of tree) {
        if (item.title.indexOf(val) > -1) {
          newArr.push(item)
          continue
        }
        if (item.children && item.children.length) {
          let subArr = this.filterTree(val, item.children)
          if (subArr && subArr.length) {
            let node = {
              ...item,
              children: subArr
            }
            newArr.push(node)
          }
        }
      }
      this.treeData = newArr;
      return newArr
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.title.indexOf(value) !== -1;
    },
    handleNodeClick(data) {
      const boo = this.selectedTextData.some(o => o.id === data.id)
      if (boo) return
      this.multiple ? this.selectedTextData.push(data) : this.selectedTextData = [data]
      this.selectedData = this.selectedTextData.map(o => o.id)
      this.$emit('input', this.selectedData)
      this.$emit('getValue', this.selectedData, this.selectedTextData)
    },
    removeData(item, index) {
      this.selectedData.splice(index, 1)
      this.selectedTextData.splice(index, 1)
      this.$emit('input', this.selectedData)
      this.$emit('getValue', this.selectedData, this.selectedTextData)
    },
    removeAll() {
      this.selectedData = []
      this.selectedTextData = []
      this.$emit('input', this.selectedData)
      this.$emit('getValue', this.selectedData, this.selectedTextData)
    },
  }
};
</script>
