var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "transfer__body",
      style: { height: _vm.height },
    },
    [
      _c("div", { staticClass: "transfer-pane" }, [
        _c(
          "div",
          { staticClass: "transfer-pane__tools" },
          [
            _c("el-input", {
              attrs: { placeholder: "输入关键词进行搜索", clearable: "" },
              on: { input: _vm.search },
              model: {
                value: _vm.filterText,
                callback: function ($$v) {
                  _vm.filterText = $$v
                },
                expression: "filterText",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "transfer-pane__body" },
          [
            _c("el-tree", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "treeBox",
              staticClass: "JNPF-common-el-tree",
              attrs: {
                data: _vm.treeData,
                props: _vm.props,
                "default-expand-all": "",
                "expand-on-click-node": false,
                "node-key": "id",
                "filter-node-method": _vm.filterNode,
              },
              on: { "node-click": _vm.handleNodeClick },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ node, data }) {
                    return _c("span", { staticClass: "custom-tree-node" }, [
                      _c("i", { class: data.icon }),
                      _c("span", { staticClass: "text" }, [
                        _vm._v(_vm._s(node.label)),
                      ]),
                    ])
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "transfer-pane" }, [
        _c(
          "div",
          { staticClass: "transfer-pane__tools" },
          [
            _c("span", [_vm._v("已选")]),
            _c(
              "el-button",
              {
                staticClass: "removeAllBtn",
                attrs: { type: "text" },
                on: { click: _vm.removeAll },
              },
              [_vm._v("清空列表")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "transfer-pane__body shadow right-pane" },
          [
            _vm._l(_vm.selectedTextData, function (item, index) {
              return _c("div", { key: index, staticClass: "selected-item" }, [
                _c("span", [_vm._v(_vm._s(item.title))]),
                _c("i", {
                  staticClass: "el-icon-delete",
                  on: {
                    click: function ($event) {
                      return _vm.removeData(item, index)
                    },
                  },
                }),
              ])
            }),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }