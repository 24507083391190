var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.allLoading,
          expression: "allLoading",
        },
      ],
      staticClass: "transfer__body",
      style: { height: _vm.height },
    },
    [
      _c("div", { staticClass: "transfer-pane" }, [
        _c(
          "div",
          { staticClass: "transfer-pane__tools" },
          [
            _c("el-input", {
              attrs: { placeholder: "输入关键词进行搜索", clearable: "" },
              on: { input: _vm.searchValue },
              model: {
                value: _vm.pagination.keyword,
                callback: function ($$v) {
                  _vm.$set(_vm.pagination, "keyword", $$v)
                },
                expression: "pagination.keyword",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "transfer-pane__body left-pane" },
          [
            !this.isAsync
              ? _c("el-tree", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "SNOWS-common-el-tree",
                  attrs: {
                    data: _vm.treeData,
                    props: _vm.props,
                    "check-on-click-node": "",
                    "node-key": "id",
                  },
                  on: { "node-click": _vm.handleNodeClick },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ node, data }) {
                          return _c(
                            "span",
                            { staticClass: "custom-tree-node" },
                            [
                              _c("i", { class: data.icon }),
                              _c("span", { staticClass: "text" }, [
                                _vm._v(_vm._s(node.label)),
                              ]),
                            ]
                          )
                        },
                      },
                    ],
                    null,
                    false,
                    1575034392
                  ),
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "transfer-pane" }, [
        _c(
          "div",
          { staticClass: "transfer-pane__tools" },
          [
            _c("span", [_vm._v("已选")]),
            _c(
              "el-button",
              {
                staticClass: "removeAllBtn",
                attrs: { type: "text" },
                on: { click: _vm.removeAll },
              },
              [_vm._v("清空列表")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "transfer-pane__body shadow right-pane" },
          [
            _vm.selectedData.length
              ? _vm._l(_vm.selectedData, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "selected-item-user" },
                    [
                      _c("div", { staticClass: "selected-item-main" }, [
                        _c("div", { staticClass: "selected-item-text" }, [
                          _c("p", { staticClass: "name" }, [
                            _vm._v(_vm._s(item.realName)),
                          ]),
                        ]),
                        _c("i", {
                          staticClass: "el-icon-delete",
                          on: {
                            click: function ($event) {
                              return _vm.removeData(index)
                            },
                          },
                        }),
                      ]),
                    ]
                  )
                })
              : _c("el-empty", {
                  attrs: { description: "暂无数据", "image-size": 120 },
                }),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }