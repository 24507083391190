<template>
  <div class="transfer__body" v-loading="allLoading" :style="{height}">
    <div class="transfer-pane">
      <div class="transfer-pane__tools">
        <el-input placeholder="输入关键词进行搜索" v-model="pagination.keyword" @input="searchValue"
          clearable>
        </el-input>
      </div>
      <div class="transfer-pane__body left-pane">
            <el-tree :data="treeData" :props="props" check-on-click-node
              @node-click="handleNodeClick" class="SNOWS-common-el-tree" node-key="id"
              v-loading="loading"  v-if="!this.isAsync">
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <i :class="data.icon"></i>
                <span class="text">{{node.label}}</span>
              </span>
            </el-tree>
      </div>
    </div>
    <div class="transfer-pane">
      <div class="transfer-pane__tools">
        <span>已选</span>
        <el-button @click="removeAll" type="text" class="removeAllBtn">清空列表</el-button>
      </div>
      <div class="transfer-pane__body shadow right-pane">
        <template v-if="selectedData.length">
          <div v-for="(item,index) in selectedData" :key="index" class="selected-item-user">
            <div class="selected-item-main">
<!--              <el-avatar :size="36" :src="define.comUrl+item.headIcon"-->
<!--                class="selected-item-headIcon">-->
<!--              </el-avatar>-->
              <div class="selected-item-text">
                <p class="name">{{item.realName}}</p>
<!--                <p class="organize" :title="item.organize">{{item.organize}}</p>-->
              </div>
              <i class="el-icon-delete" @click="removeData(index)"></i>
            </div>
          </div>
        </template>
        <el-empty description="暂无数据" :image-size="120" v-else></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import {getImUserSelector, getUserInfoList, getSubordinates, getOrganization} from '@/api/onlineDev/user'
import {throttle} from 'throttle-debounce'
import { listUser } from '@/api/system/user'
export default {
  name: 'SNOWS-userTransfer',
  data() {
    return {
      allLoading: false,
      loading: false,
      activeName: '',
      treeData: [],
      treeDataBak:[],
      selectedData: [],
      props: {
        children: 'children',
        label: 'realName',
      },
      nodeId: '0',
      ids: [],
      total: 0,
      isAsync: false,
      finish: false,
      listLoading: false,
      pagination: {
        keyword: '',
        currentPage: 1,
        pageSize: 20,
      }
    }
  },
  props: {
    height: {
      type: String,
      default: "400px"
    },
    // allLoading: {
    //   type: Boolean,
    //   default: false
    // },
    value: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    activeName(val) {
      this.pagination.keyword = ''
      this.isAsync = false
      if (!val) return
      this.nodeId = '0'
      this.treeData = []
      this.getData()
    }
  },
  methods: {
    init() {
      this.getData()
      this.selectedData = []
      this.ids = []
      this.pagination.keyword = ''
      this.activeName = ''
      this.nodeId = '0'
      this.isAsync = false
      this.finish = false
      this.$nextTick(() => {
        this.getSelectList()
      })
    },
    bindScroll() {
      let _this = this,
        vBody = _this.$refs.infiniteBody;
      vBody.addEventListener("scroll", throttle(300, function () {
        if (vBody.scrollHeight - vBody.clientHeight - vBody.scrollTop <= 200 && !_this.listLoading && !_this.finish) {
          _this.pagination.currentPage += 1
          _this.getList()
        }
      }));
    },
    getData() {
        this.getList()
    },
    getSelectList() {
      this.allLoading = true
      if (!this.value.length) return this.allLoading = false
      this.getList()
      this.selectedData = this.treeData.filter(item => this.value.includes(item.id))
      this.ids = this.selectedData.map(o => o.id)
      this.allLoading = false
      /*getUserInfoList(this.value).then(res => {
        this.selectedData = res.data.list
        this.ids = this.selectedData.map(o => o.id)
        this.allLoading = false
      })*/
    },
    search() {
      this.nodeId = '0'
      this.treeData = []
      this.pagination.currentPage = 1
      this.isAsync = !!this.pagination.keyword
      this.finish = false
      if (this.isAsync && this.activeName === 'all') {
        this.$nextTick(() => {
          this.bindScroll()
        })
      }
      this.getData()
    },
    searchValue(e) {
      if (e == '') {
        this.treeData = this.treeDataBak;
        return;
      }
      this.treeData = this.treeDataBak.filter((item) => {
        if (item.realName.indexOf(e) > -1) {
          return true
        }
      })
    },
    getList() {
      this.loading = true;
      this.treeData = JSON.parse(localStorage.getItem('sinoma-userData')).content;
      this.treeDataBak = JSON.parse(localStorage.getItem('sinoma-userData')).content;
      this.loading = false;
    },
    handleNodeClick(data) {
      this.handleNodeClick2(data)
    },
    handleNodeClick2(data) {
      const boo = this.selectedData.some(o => o.id === data.id)
      if (boo) return
      this.multiple ? this.selectedData.push(data) : this.selectedData = [data]
      this.multiple ? this.ids.push(data.id) : this.ids = [data.id]
      this.$emit('input', this.ids)
      this.$emit('getValue', this.ids, this.selectedData)
    },
    removeAll() {
      this.selectedData = []
      this.ids = []
      this.$emit('input', this.ids)
      this.$emit('getValue', this.ids, this.selectedData)
    },
    removeData(index) {
      this.selectedData.splice(index, 1)
      this.ids.splice(index, 1)
      this.$emit('input', this.ids)
      this.$emit('getValue', this.ids, this.selectedData)
    },
  }
};
</script>
