<template>
  <div class="transfer__body" v-loading="allLoading" :style="{height}">
    <div class="transfer-pane">
      <div class="transfer-pane__tools">
        <el-input placeholder="输入关键词进行搜索" v-model="keyword" @input="filterRole" clearable>
        </el-input>
      </div>
      <div class="transfer-pane__body">
            <el-tree :data="treeData" :props="props" ref="tree1" default-expand-all
              :filter-node-method="filterNode" :expand-on-click-node="false" check-on-click-node
              @node-click="handleNodeClick" class="SNOWS-common-el-tree" node-key="onlyId"
              v-loading="loading">
              <span class="custom-tree-node" slot-scope="{ node,data }">
                <i :class="data.icon" />
                <span class="text">{{node.label}}</span>
              </span>
            </el-tree>
      </div>
    </div>
    <div class="transfer-pane">
      <div class="transfer-pane__tools">
        <span>已选</span>
        <el-button @click="removeAll" type="text" class="removeAllBtn">清空列表</el-button>
      </div>
      <div class="transfer-pane__body shadow right-pane">
        <template>
          <div v-for="(item, index) in selectedData" :key=" index" class="selected-item">
            <span>{{ item.fullName}}</span>
            <i class="el-icon-delete" @click="removeData(index)"></i>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { getRoleTree } from '@/api/system/role'
export default {
  name: 'SNOWS-roleTransfer',
  data() {
    return {
      allLoading: false,
      loading: false,
      activeName: '',
      treeDataBak: [],
      treeData: [],
      treeData2: [],
      selectedData: [],
      allList: [],
      props: {
        children: 'children',
        label: 'roleName'
      },
      keyword: '',
      ids: []
    }
  },
  props: {
    height: {
      type: String,
      default: "400px"
    },
    value: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    },
    auth: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    init() {
      this.selectedData = []
      this.getSelectList()
      this.ids = []
      this.keyword = ''
      this.getList()
    },
    getNodeById(id) {
      let tree = JSON.parse(localStorage.getItem('sinoma-roleData')).content;
      let arr = Array.isArray(tree) ? tree : [tree]
      let result = null
      while (arr.length) {
        let item = arr.pop()
        if (item && item.id === id) {
          result = item
          break
        } else if (item && item.children && item.children.length) {
          arr.push(...item.children)
        }
      }
      return result
    },
    getSelectList() {
      this.allLoading = true
      if (!this.value.length) return this.allLoading = false
      let list = []
      this.value.forEach(item => {
        list.push(this.getNodeById(item))
      })
    /*  for (let i = 0; i < this.value.length; i++) {
        inner: for (let j = 0; j < this.allList.length; j++) {
          if (this.value[i] === this.allList[j].id) {
            list.push(this.allList[j])
            break inner
          }
        }
      }*/
      this.selectedData = list
      this.ids = this.selectedData.map(o => o.id)
      this.allLoading = false
    },
    filterRole(e){
      if(!e){
        this.treeData = this.treeDataBak
        return
      }
      this.treeData = this.treeData.filter(item => item.title.indexOf(e)>0)
    },
    filterNode(value, data) {
      if (!value) return true;
      return data[this.props.label].indexOf(value) !== -1;
    },
    getList() {
      this.loading = true;
      this.treeData = JSON.parse(localStorage.getItem('sinoma-roleData')).content;
      this.treeDataBak = JSON.parse(localStorage.getItem('sinoma-roleData')).content;
      this.loading = false;
    },
    handleNodeClick(data) {
      // if (data.type !== 'role') return
      const boo = this.selectedData.some(o => o.id === data.id)
      if (boo) return
      const item = {
        id: data.id,
        fullName: data.roleName
      }
      this.multiple ? this.selectedData.push(item) : this.selectedData = [item]
      this.multiple ? this.ids.push(item.id) : this.ids = [item.id]
      this.$emit('input', this.ids)
      this.$emit('getValue', this.ids, this.selectedData)
    },
    removeAll() {
      this.selectedData = []
      this.ids = []
      this.$emit('input', this.ids)
      this.$emit('getValue', this.ids, this.selectedData)
    },
    removeData(index) {
      this.selectedData.splice(index, 1)
      this.ids.splice(index, 1)
      this.$emit('input', this.ids)
      this.$emit('getValue', this.ids, this.selectedData)
    },
  }
};
</script>
<style lang="scss" scoped>
.transfer-pane__body-tab {
  ::v-deep .el-tabs__item {
    width: 50% !important;
  }
}
</style>
