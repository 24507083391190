var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.allLoading,
          expression: "allLoading",
        },
      ],
      staticClass: "transfer__body",
      style: { height: _vm.height },
    },
    [
      _c("div", { staticClass: "transfer-pane" }, [
        _c(
          "div",
          { staticClass: "transfer-pane__tools" },
          [
            _c(
              "el-input",
              {
                attrs: { placeholder: "输入关键词进行搜索", clearable: "" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.search.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.pagination.keyword,
                  callback: function ($$v) {
                    _vm.$set(_vm.pagination, "keyword", $$v)
                  },
                  expression: "pagination.keyword",
                },
              },
              [
                _c("el-button", {
                  attrs: { slot: "append", icon: "el-icon-search" },
                  on: { click: _vm.search },
                  slot: "append",
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "transfer-pane__body left-pane" },
          [
            _c(
              "el-tabs",
              {
                staticClass: "transfer-pane__body-tab hasSys-tab",
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "部门", name: "department" } },
                  [
                    !this.isAsync
                      ? _c("el-tree", {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          staticClass: "SNOWS-common-el-tree",
                          attrs: {
                            data: _vm.treeData,
                            props: _vm.props,
                            "check-on-click-node": "",
                            "expand-on-click-node": false,
                            "node-key": "id",
                            lazy: "",
                            load: _vm.loadNode,
                          },
                          on: { "node-click": _vm.handleNodeClick },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ node, data }) {
                                  return _c(
                                    "span",
                                    { staticClass: "custom-tree-node" },
                                    [
                                      _c("i", { class: data.icon }),
                                      _c("span", { staticClass: "text" }, [
                                        _vm._v(_vm._s(node.label)),
                                      ]),
                                    ]
                                  )
                                },
                              },
                            ],
                            null,
                            false,
                            1575034392
                          ),
                        })
                      : _vm._e(),
                    this.isAsync
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value:
                                  _vm.loading &&
                                  _vm.pagination.currentPage == 1,
                                expression:
                                  "loading && pagination.currentPage==1",
                              },
                            ],
                            ref: "infiniteBody",
                            staticClass: "single-list",
                          },
                          [
                            _vm.treeData.length
                              ? _vm._l(_vm.treeData, function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "selected-item-user",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleNodeClick(item)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "selected-item-main" },
                                        [
                                          _c("el-avatar", {
                                            staticClass:
                                              "selected-item-headIcon",
                                            attrs: {
                                              size: 36,
                                              src:
                                                _vm.define.comUrl +
                                                item.headIcon,
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "selected-item-text",
                                            },
                                            [
                                              _c("p", { staticClass: "name" }, [
                                                _vm._v(_vm._s(item.fullName)),
                                              ]),
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "organize",
                                                  attrs: {
                                                    title: item.organize,
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.organize))]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                })
                              : _c("el-empty", {
                                  attrs: {
                                    description: "暂无数据",
                                    "image-size": 120,
                                  },
                                }),
                          ],
                          2
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.multiple
                  ? _c(
                      "el-tab-pane",
                      { attrs: { label: "角色", name: "role" } },
                      [
                        _c("el-tree", {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.roleLoading,
                              expression: "roleLoading",
                            },
                          ],
                          ref: "roleTree",
                          staticClass: "SNOWS-common-el-tree",
                          attrs: {
                            data: _vm.treeData2,
                            props: _vm.props,
                            "expand-on-click-node": false,
                            "default-expand-all": "",
                            "check-on-click-node": "",
                            "node-key": "id",
                            "filter-node-method": _vm.filterNode,
                          },
                          on: { "node-click": _vm.handleNodeClick },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ node, data }) {
                                  return _c(
                                    "span",
                                    { staticClass: "custom-tree-node" },
                                    [
                                      _c("i", { class: data.icon }),
                                      _c("span", { staticClass: "text" }, [
                                        _vm._v(_vm._s(node.label)),
                                      ]),
                                    ]
                                  )
                                },
                              },
                            ],
                            null,
                            false,
                            1575034392
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.multiple
                  ? _c(
                      "el-tab-pane",
                      { attrs: { label: "岗位", name: "position" } },
                      [
                        _c("el-tree", {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.positionLoading,
                              expression: "positionLoading",
                            },
                          ],
                          ref: "positionTree",
                          staticClass: "SNOWS-common-el-tree",
                          attrs: {
                            data: _vm.treeData3,
                            props: _vm.props,
                            "expand-on-click-node": false,
                            "default-expand-all": "",
                            "check-on-click-node": "",
                            "node-key": "id",
                            "filter-node-method": _vm.filterNode,
                          },
                          on: { "node-click": _vm.handleNodeClick },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ node, data }) {
                                  return _c(
                                    "span",
                                    { staticClass: "custom-tree-node" },
                                    [
                                      _c("i", { class: data.icon }),
                                      _c("span", { staticClass: "text" }, [
                                        _vm._v(_vm._s(node.label)),
                                      ]),
                                    ]
                                  )
                                },
                              },
                            ],
                            null,
                            false,
                            1575034392
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.multiple
                  ? _c(
                      "el-tab-pane",
                      { attrs: { label: "分组", name: "group" } },
                      [
                        _c("el-tree", {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.groupLoading,
                              expression: "groupLoading",
                            },
                          ],
                          ref: "groupTree",
                          staticClass: "SNOWS-common-el-tree",
                          attrs: {
                            data: _vm.treeData4,
                            props: _vm.props,
                            "expand-on-click-node": false,
                            "default-expand-all": "",
                            "check-on-click-node": "",
                            "node-key": "id",
                            "filter-node-method": _vm.filterNode,
                          },
                          on: { "node-click": _vm.handleNodeClick },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ node, data }) {
                                  return _c(
                                    "span",
                                    { staticClass: "custom-tree-node" },
                                    [
                                      _c("i", { class: data.icon }),
                                      _c("span", { staticClass: "text" }, [
                                        _vm._v(_vm._s(node.label)),
                                      ]),
                                    ]
                                  )
                                },
                              },
                            ],
                            null,
                            false,
                            1575034392
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "transfer-pane" }, [
        _c(
          "div",
          { staticClass: "transfer-pane__tools" },
          [
            _c("span", [_vm._v("已选")]),
            _c(
              "el-button",
              {
                staticClass: "removeAllBtn",
                attrs: { type: "text" },
                on: { click: _vm.removeAll },
              },
              [_vm._v("清空列表")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "transfer-pane__body shadow right-pane" },
          [
            _vm.selectedData.length
              ? _vm._l(_vm.selectedData, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "selected-item-user" },
                    [
                      _c(
                        "div",
                        { staticClass: "selected-item-main" },
                        [
                          item.type === "user"
                            ? _c("el-avatar", {
                                staticClass: "selected-item-headIcon",
                                attrs: {
                                  size: 36,
                                  src: _vm.define.comUrl + item.headIcon,
                                },
                              })
                            : _c(
                                "div",
                                { staticClass: "selected-item-headIcon icon" },
                                [_c("i", { class: item.icon })]
                              ),
                          _c("div", { staticClass: "selected-item-text" }, [
                            _c("p", { staticClass: "name" }, [
                              _vm._v(_vm._s(item.fullName)),
                            ]),
                            _c(
                              "p",
                              {
                                staticClass: "organize",
                                attrs: { title: item.organize },
                              },
                              [_vm._v(_vm._s(item.organize))]
                            ),
                          ]),
                          _c("i", {
                            staticClass: "el-icon-delete",
                            on: {
                              click: function ($event) {
                                return _vm.removeData(index)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                })
              : _c("el-empty", {
                  attrs: { description: "暂无数据", "image-size": 120 },
                }),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }